

h2 {
  text-align: center;
}

.avatar {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #212529;
}